body {
  background-color: rgb(23, 23, 88);
  /* Adjust the path to your background image */
  background-repeat: round;
  background-size: 800px 800px;
  background-attachment: fixed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-text h5 {
  color: #F1B61E;
}

.custom-card:hover {
  transform: none !important; /* Remove any transformations */
  border:none;/* Reset border color */
  box-shadow: none !important; /* Remove the box shadow */
  border-width: initial !important; /* Reset border width */
}

.circular-image {
  max-width: 110px; /* Adjust the width as needed */
  max-height: 138px; /* Ensure the height is the same as the width */
  object-fit: cover; /* Cover the entire area */
  margin: 1px auto; /* Center the image horizontally */
}

.card-img-no-border {
  border: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.card-wrapper {
  display: flex;
  justify-content: center;
}

.custom-card {
  width: 100%;
  max-width: auto; /* Adjust the max-width as needed */
}

@media screen and (max-width: 733px) {
  .custom-card {
      width: 100%;
  }
}
